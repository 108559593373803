import axios from "axios"
import { store } from "../state/store"

const year = new Date().getFullYear()
const month =
  new Date().getMonth() + 1 > 9
    ? new Date().getMonth() + 1
    : "0" + (new Date().getMonth() + 1)
const date = new Date().getDate()

export default async function getPressRelease(tagList) {
  const state = store.getState()
  const params = {
    tagList: tagList,
    pageNumber: 0,
    pageSize: 5,
    includeTags: true,
    pressReleaseDateFilter: 2,
    startDate: year - 2 + "/" + month + "/" + date,
    endDate: year + "/" + month + "/" + date,
  }
  try {
    if (state.config.GATSBY_NEWS_API_URL) {
      const response = await axios.get(state.config.GATSBY_NEWS_API_URL, {
        params: params,
      })
      return response.data.GetPressReleaseListResult
    }
  } catch (error) {
    console.error(error.message)
  }
  return null
}
