/* eslint-disable react/display-name */
import React, { useState } from "react"
import {
  PageInformation,
  Tagman,
  RightRail,
  Carousel,
  IntroContent,
  LinkList,
  TransactionModule,
  Body,
  PagePromo,
  FooterCap,
  Disclaimer,
  DisclaimerContextProvider,
} from "trv-ebus-tcom-reactcomponents"
import { useStore } from "react-redux"

const getTagmanUrls = () => {
  const store = useStore()
  const utagUrl = store.getState().config.UTAG_URL
  return [utagUrl]
}

export const getContentFromId = (ids, json) => {
  let contentItems = []
  ids.forEach((id) => {
    const item = json.find((item) => item.id === id)
    if (item) contentItems.push(item)
  })
  return contentItems
}

const componentsMap = [
  [
    "valueDrivers",
    {
      path: "components/ValueDrivers",
      propMap: (component) => {
        return {
          title: component.options.title,
          subTitle: component.options.subTitle,
          content: component.options.content,
          href: component.options.href,
          linkTitle: component.options.linkTitle,
          linkId: component.options.linkId,
        }
      },
    },
  ],
  [
    "intro",
    {
      path: "components/Intro",
      propMap: (component) => {
        return {
          className: component.content.class,
          children: component.content.content,
        }
      },
    },
  ],
  [
    "stockticker",
    {
      path: "components/Newsbar/StockTicker",
      propMap: () => {
        return {}
      },
    },
  ],
  [
    "news",
    {
      path: "components/Newsbar/News",
      propMap: (component) => {
        return {
          tagList: component.content ? component.content.tagList : null,
        }
      },
    },
  ],
  [
    "highlight",
    {
      path: "components/Newsbar/Highlight",
      propMap: (component) => {
        return {
          img: component.content.img,
          text: component.content.text,
        }
      },
    },
  ],
  [
    "Tagman",
    {
      init: (component) => {
        return (
          <Tagman
            pageId={component.tagman.pageId}
            appId={component.tagman.appId}
            tagManagerUrls={getTagmanUrls()}
          />
        )
      },
    },
  ],
  [
    "Page_Information",
    {
      init: (component) => (
        <PageInformation
          title={component.pageTitle}
          description={component.pageDescription}
          keywords={
            component.metadata && component.metadata.keywords
              ? component.metadata.keywords.join(",")
              : ""
          }
          alternatePages={component.hrefLangItems}
          sharingTool={component.sharingTool}
        />
      ),
    },
  ],
  [
    "Right_Rail",
    {
      init: (component) => <RightRail rails={component.rails} />,
    },
  ],
  [
    "Carousel",
    {
      init: (component) => (
        <Carousel
          desktopHeroType={component.desktopHeroType}
          heroes={component.heroes}
        />
      ),
    },
  ],
  [
    "Link_List",
    {
      init: (component) => (
        <LinkList
          listTitle={component.listTitle}
          listDescription={component.listDescription}
          listType={component.listType}
          layoutType={component.layoutType}
          links={component.links}
        />
      ),
    },
  ],
  [
    "Body_Content",
    {
      init: (component) => (
        <Body
          pageType={component.pageType}
          rows={component.rows}
          metadata={component.metadata}
          articleHeading={component.articleHeading}
          sharingToolLocation={component.sharingToolLocation}
          bitlyUrl={component.bitlyUrl}
        />
      ),
    },
  ],
  [
    "Intro_Content",
    {
      init: (component) => (
        <IntroContent
          heading={component.heading}
          copy={component.copy}
          readMoreLess={component.readMoreLess}
          includeContainer={component.includeContainer}
        />
      ),
    },
  ],
  [
    "Page_Promo",
    {
      init: (component) => (
        <PagePromo
          layout={component.layout}
          fullPromo={component.fullPromo}
          halfPromos={component.halfPromos}
        />
      ),
    },
  ],
  [
    "Footer_Cap",
    {
      init: (component) => (
        <FooterCap
          imageSrc={component.fullPromo.imageSrc}
          imageAlt={component.fullPromo.imageAlt}
          heading={component.fullPromo.heading}
          subheading={component.fullPromo.subheading}
          callToAction={component.fullPromo.callToAction}
        />
      ),
    },
  ],
  [
    "Disclaimer",
    {
      init: (component) => {
        const getOtherDisclaimers = () => []
        return (
          <DisclaimerContextProvider value={{ disclaimers: [] }}>
            <Disclaimer
              disclaimers={[
                ...getOtherDisclaimers(),
                {
                  footnoteIndex: "",
                  placement: "Bottom",
                  text: component.disclaimerText,
                  type: "Page",
                },
              ]}
            />
          </DisclaimerContextProvider>
        )
      },
    },
  ],
]

const getDownloadLinks = (component, allDownloads) => {
  return {
    heading: component.content.heading,
    downloads: getContentFromId(component.content.links, allDownloads),
    columns: component.content.columns,
  }
}
export function getHybridComponentsMap({ allDownloads }) {
  const [zipcode, setZipcode] = useState("")
  const [selectedProduct, setSelectedProduct] = useState({})

  return new Map([
    ...componentsMap,
    [
      "downloads",
      {
        path: "components/Newsbar/Downloads",
        propMap: (component) => getDownloadLinks(component, allDownloads),
      },
    ],
    [
      "codes",
      {
        path: "components/Newsbar/Downloads",
        propMap: (component) => getDownloadLinks(component, allDownloads),
      },
    ],
    [
      "Transaction_Module",
      {
        init: (component) => (
          <TransactionModule
            tabInc={component.transactionModules[0].tabInc}
            piDynamicCta={component.transactionModules[0].piDynamicCta}
            title={"Get a Quote"}
            submitButton={"Start Quote"}
            selectedProduct={selectedProduct}
            setSelectedProduct={(product) => setSelectedProduct(product)}
            zipcode={{
              id: "gaq-zip-code",
              name: "zip-code",
              maxlength: 5,
              placeholder: "ZIP Code",
              pattern: "[0-9]*",
              value: zipcode,
              handleZipcodeChange: (e) => setZipcode(e.currentTarget.value),
            }}
            handleSubmit={() => console.log("Submit button clicked")}
            handleDropdownClick={() => console.log("Dropdown option clicked")}
            handleMobileDropdownChange={() =>
              console.log("Mobile dropdown option change")
            }
          />
        ),
      },
    ],
  ])
}
