import { getHybridComponentLoader } from "./getHybridComponentLoader"
import { getHybridComponentsMap } from "./getHybridComponentsMap"
import getSitewideMessages from "./getSiteWideMessages"

const getTemplateData = (data, location) => {
  const {
    json = {},
    allNewsJson = {},
    allDownloadsJson = {},
    ecsSitewideMessages = {},
  } = data
  const allDownloads = allDownloadsJson.edges.map((edge) => edge.node)
  const allNews = allNewsJson.edges.map((edge) => edge.node)

  const sitewideMessages = getSitewideMessages(
    ecsSitewideMessages.content,
    location.pathname
  )
  const hybridComponentsMap = getHybridComponentsMap({
    allDownloads,
    allNews,
    json,
  })
  const hybridComponents = [
    ...(data.ecsPage.content.components || []),
    ...(json?.components || []),
  ]
  const components = getHybridComponentLoader(
    hybridComponentsMap,
    hybridComponents
  )
  const headData = {
    pageInformation: components["Page_Information"],
    tagman: components["Tagman"],
    htmlAttributes: { lang: "en-us" },
    canonicalUrl: location.href,
  }

  const globalHeader = {
    pageUrl: data.ecsPage.slug,
  }
  return { headData, components, sitewideMessages, globalHeader }
}
export default getTemplateData
