import React from "react"
import { array, number, string, shape } from "prop-types"
import { ReactComponent as Download118 } from "@trv-ebus/tds-icons/dist/assets/icons/functional/svg/download-1-18.svg"
import "./Downloads.scss"

export default function Downloads({
  heading,
  downloads,
  columns = { mobile: 2, tablet: 3, desktop: 2 },
}) {
  return (
    <div className="newsbar-downloads">
      <h2>{heading}</h2>
      {downloads.map((item, index) => (
        <a
          key={index}
          className={`newsbar-download-link col-mob-${columns.mobile} col-tab-${columns.tablet} col-desk-${columns.desktop}`}
          href={item.href}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Download118
            className="download-icon tds-icon-18"
            alt="Download PDF"
          />
          {item.title}
        </a>
      ))}
    </div>
  )
}

Downloads.propTypes = {
  heading: string.isRequired,
  downloads: array.isRequired,
  columns: shape({
    mobile: number,
    tablet: number,
    desktop: number,
  }),
}
