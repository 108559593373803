import React, { useState, useEffect } from "react"
import getStock from "../../services/getStock"
import { formatDateToLongString } from "../../utilities/formatDate"
import "./StockTicker.scss"

export function formatNumber(number) {
  return `${number > 0 ? "+" : ""}${number.toFixed(2)}`
}

export default function StockTicker() {
  const [stock, setStock] = useState(null)

  useEffect(() => {
    async function fetchData() {
      const response = await getStock()
      if (response) {
        setStock(response)
      } else {
        setStock(null)
      }
    }
    fetchData()
  }, [])

  return (
    <div className="newsbar-stockticker">
      <h2>NYSE: TRV</h2>
      {stock ? (
        <>
          <div>
            <span className="currency">$</span>
            <span className="stock">{stock.TradePrice}</span>
            <span className="change">{`${formatNumber(
              stock.Change
            )} (${formatNumber(stock.PercChange)}%)`}</span>
          </div>
          <div className="stock-disclaimer">
            Pricing delayed by 20 min. {formatDateToLongString(stock.TradeDate)}
          </div>
        </>
      ) : (
        <div className="newsbar-error">
          We&apos;re sorry. Due to a technical issue, we&apos;re unable to
          provide the stock price at this time.
        </div>
      )}
    </div>
  )
}
