/* eslint-disable react/display-name */
import React from "react"
import Loadable from "react-loadable"

let contentPropName = "content"
let contentTypeName = "type"

function initializeModules({ components, availableComponentsMap }) {
  let modules = []
  components &&
    components.forEach((component) => {
      const componentType = component.type || component.name
      if (availableComponentsMap.has(componentType)) {
        getAvailableComponentsMap(
          availableComponentsMap,
          componentType,
          component,
          modules
        )
      } else {
        modules.push({
          module: null,
          content: component.content,
          type: component.type,
        })
      }
    })
  return modules
}

function getAvailableComponentsMap(
  availableComponentsMap,
  componentType,
  component,
  modules
) {
  let mappedComponent = availableComponentsMap.get(componentType)
  if (mappedComponent.init) {
    processComponentsByName(
      component,
      availableComponentsMap,
      modules,
      mappedComponent
    )
  } else {
    // For JSON components
    let props = mappedComponent.propMap(component)
    nestedComponentLoader(component.content, availableComponentsMap)
    modules.push({
      module: Loadable({
        loader: () => {
          return import("../" + availableComponentsMap.get(component.type).path)
        },
        loading: () => <></>,
        webpack: () => [
          require.resolveWeak(
            "../" + availableComponentsMap.get(component.type).path
          ),
        ],
      }),
      content: component.content,
      type: component.type,
      props: props,
    })
  }
}

function processComponentsByName(
  component,
  availableComponentsMap,
  modules,
  mappedComponent
) {
  if (component.name) {
    // For cms
    const name = component.name
    processDcr(component, availableComponentsMap, modules, name)
  } else {
    // For static components
    modules.push({
      isComponent: true,
      type: component.type,
      module: mappedComponent.init(component),
    })
  }
  return modules
}

function processDcr(component, availableComponentsMap, modules, name) {
  const dcr =
    component.segments[0].segmentItemDetails[0].parameterItemDetails[0].dcr
  if (dcr) {
    const mappedComponent = availableComponentsMap.get(component.name)
    if (mappedComponent) {
      modules.push({
        isComponent: true,
        type: component.name,
        module: mappedComponent.init(dcr),
      })
    }

    getPageInformationTagman(name, dcr, availableComponentsMap, modules)
  }
  return modules
}

function getPageInformationTagman(name, dcr, availableComponentsMap, modules) {
  if (name === "Page_Information" && dcr.tagman) {
    const tagman = availableComponentsMap.get("Tagman")
    if (tagman) {
      modules.push({
        isComponent: true,
        type: "Tagman",
        module: tagman.init(dcr),
      })
    }
  }
}

function nestedComponentLoader(component, availableComponentsMap) {
  if (component instanceof Array) {
    component.forEach((item) => {
      nestedComponentLoader(item, availableComponentsMap)
    })
  } else if (component instanceof Object) {
    if (
      Object.prototype.hasOwnProperty.call(component, contentPropName) &&
      component[contentPropName] instanceof Object
    ) {
      if (
        component[contentTypeName] !== undefined &&
        availableComponentsMap.has(component[contentTypeName])
      ) {
        createLoadableObject(component)
        nestedComponentLoader(
          component[contentPropName],
          availableComponentsMap
        )
      } else if (
        component[contentPropName][contentTypeName] !== undefined &&
        availableComponentsMap.has(component[contentPropName][contentTypeName])
      ) {
        createLoadableObject(component[contentPropName])
        nestedComponentLoader(
          component[contentPropName][contentPropName],
          availableComponentsMap
        )
      } else if (component[contentPropName] instanceof Array) {
        nestedComponentLoader(
          component[contentPropName],
          availableComponentsMap
        )
      }
    }
  }
}

function createLoadableObject(component) {
  let { availableComponentsMap } = this.props
  if (
    component[contentTypeName] === undefined ||
    !availableComponentsMap.has(component[contentTypeName])
  ) {
    return component
  }

  let props = availableComponentsMap.get(component.type).propMap(component)
  component.Props = props
  component.Module = Loadable({
    loader: () => {
      return import(
        "../" + availableComponentsMap.get(component[contentTypeName]).path
      )
    },
    loading: () => <span>Loading...</span>,
    webpack: () => [
      require.resolveWeak(
        "../" + availableComponentsMap.get(component[contentTypeName]).path
      ),
    ],
  })
  return component
}

export function getHybridComponentLoader(availableComponentsMap, components) {
  const modules = initializeModules({ components, availableComponentsMap })
  const componentDictionary = {}
  modules &&
    modules.forEach((item, i) => {
      if (availableComponentsMap.has(item.type)) {
        let Module = item.module
        componentDictionary[item.type] = item.isComponent ? (
          item.module
        ) : (
          <Module key={i} {...item.props} />
        )
      } else {
        componentDictionary[item.type] = (
          <span
            key={i}
            dangerouslySetInnerHTML={{
              __html: item.content,
            }}
          />
        )
      }
    })

  return componentDictionary
}
