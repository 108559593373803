import React from "react"
import { node } from "prop-types"
import "./Newsbar.scss"

export default function Newsbar({ children }) {
  if (Array.isArray(children) && children.every((item) => item === undefined))
    return null
  return (
    <div className="newsbar-container" role="complementary" aria-label="news">
      <div className="newsbar">{children}</div>
    </div>
  )
}

Newsbar.propTypes = {
  children: node.isRequired,
}
