import React, { useEffect, useRef, useState } from "react"
import { Swiper, SwiperSlide } from "swiper/react"
import SwiperCore, { Autoplay, Pagination, A11y } from "swiper"
import { ReactComponent as ArrowUpRightBox112 } from "@trv-ebus/tds-icons/dist/assets/icons/functional/svg/arrow-up-right-box-1-12.svg"
import getPressRelease from "../../services/getPressRelease"
import useWindowWidth from "../../utilities/useWindowWidth"
import { formatDate } from "../../utilities/formatDate"
import { string } from "prop-types"
import "./News.scss"

SwiperCore.use([Autoplay, Pagination, A11y])

export default function News({ tagList }) {
  const [newsItems, setNewsItems] = useState(null)

  let swiperRef = useRef(null)
  const { windowWidth, isDesktop } = useWindowWidth()
  const stopAutoplay = () => swiperRef.current.swiper.autoplay.stop()
  const startAutoplay = () => swiperRef.current.swiper.autoplay.start()

  const autoPlayByWindowWidth = () => {
    if (swiperRef.current) {
      isDesktop ? startAutoplay() : stopAutoplay()
    }
  }

  const getNewsItems = (newsItem) => {
    return newsItem.Headline.length > 140
      ? newsItem.Headline.substring(0, 140) + "..."
      : newsItem.Headline
  }

  useEffect(() => {
    autoPlayByWindowWidth()
  }, [windowWidth])

  useEffect(() => {
    if (swiperRef.current && isDesktop && newsItems && newsItems.length > 1) {
      const current = swiperRef.current
      current.addEventListener("mouseenter", stopAutoplay)
      current.addEventListener("mouseleave", startAutoplay)
    }
    return () => {
      swiperRef?.current?.removeEventListener("mouseenter", stopAutoplay)
      swiperRef?.current?.removeEventListener("mouseleave", startAutoplay)
    }
  }, [windowWidth, newsItems])

  useEffect(() => {
    if (newsItems && newsItems.length === 1 && swiperRef.current) {
      stopAutoplay()
      swiperRef.current.swiper.allowTouchMove = false
    }
  })

  useEffect(() => {
    async function fetchData() {
      const response = await getPressRelease(tagList)
      if (response) {
        setNewsItems(response)
      }
    }
    fetchData()
  }, [tagList])

  const getSwiper = () =>
    newsItems && newsItems.length > 0 ? (
      <>
        <div className="swiper-pagination" />
        <Swiper
          ref={swiperRef}
          spaceBetween={20}
          slidesPerView={1}
          loop={true}
          autoplay={{
            delay: 3000,
            disableOnInteraction: false,
            stopOnLastSlide: true,
          }}
          speed={300}
          pagination={{
            type: "bullets",
            clickable: true,
            el: ".swiper-pagination",
            bulletElement: "button",
          }}
          a11y={{
            enabled: true,
            paginationBulletMessage: `Go to slide {{index}}`,
          }}
        >
          {newsItems.map((newsItem) => (
            <SwiperSlide key={`heading-${newsItem.PressReleaseId}`}>
              <p className="date">{formatDate(newsItem.PressReleaseDate)}</p>
              <a
                data-id={newsItem.PressReleaseId}
                href={
                  "https://investor.travelers.com" + newsItem.LinkToDetailPage
                }
                className="headline"
                title={newsItem.Headline}
                target="_blank"
                rel="noopener noreferrer"
              >
                {getNewsItems(newsItem)}
                <ArrowUpRightBox112
                  title="Opens in a new window"
                  className="tds-margin-left-xx-small arrow-right-box tds-icon-12"
                />
              </a>
            </SwiperSlide>
          ))}
        </Swiper>
      </>
    ) : (
      <div className="newsbar-error">
        We&apos;re sorry. Due to a technical issue, we&apos;re unable to provide
        news articles at this time.
      </div>
    )
  return (
    <div className="newsbar-news">
      <h2>NEWS</h2>
      {getSwiper()}
    </div>
  )
}

News.propTypes = {
  tagList: string,
}
