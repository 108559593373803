import React from "react"
import { string } from "prop-types"

export default function Highlight({ img, text }) {
  return (
    <div className="newsbar-performance">
      <h2>HIGHLIGHT</h2>
      <p className="headline">{img}</p>
      <p className="description" dangerouslySetInnerHTML={{ __html: text }}></p>
    </div>
  )
}

Highlight.propTypes = {
  img: string.isRequired,
  text: string.isRequired,
}
