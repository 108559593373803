export function formatDateToLongString(date) {
  const formatter = new Intl.DateTimeFormat("en-us", {
    month: "long",
    day: "numeric",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
  })

  let formattedDate
  try {
    formattedDate = date ? formatter.format(new Date(date)) : ""
  } catch (error) {
    formattedDate = ""
  }

  return formattedDate
}

function twoDigitFormat(number) {
  if (number > 0 && number < 10) {
    return `0${number}`
  } else {
    return `${number}`
  }
}

export function formatDate(dateString) {
  const date = new Date(dateString)
  return date.toString() !== "Invalid Date"
    ? `${twoDigitFormat(date.getMonth() + 1)}.${twoDigitFormat(
        date.getDate()
      )}.${date.getFullYear()}`
    : ""
}
