import axios from "axios"
import { store } from "../state/store"

export default async function getStock() {
  try {
    const state = store.getState()
    let response = await axios.get(`${state.config.GATSBY_STOCK_API_URL}`)
    return response.data.GetStockQuoteListResult[0]
  } catch (error) {
    console.error(error.message)
  }
  return null
}
